/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system
//NPM
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/@fullcalendar/common/main.min.css';
//Common
@import 'app/common-styles/general.scss';
@import 'app/common-styles/table.scss';
@import 'app/common-styles/colours.scss';
@import 'app/common-styles/loading-spinner.scss';
@import 'app/common-styles/calendar.scss';
@import 'app/common-styles/workflow-types.scss';

//Main Style
@import 'app/app.component.scss';

//Components
@import 'app/components/dashboard/dashboard.component.scss';

@import 'app/components/tasks-detail/tasks-detail.component.scss';
@import 'app/components/taskboard/taskboard.component.scss';
@import 'app/components/reporting/audit-report/audit-report.component.scss';
@import 'app/components/menu/menu.component.scss';
//Panel Components
@import 'app/panel-components/workflow-table/workflow-table.component.scss';
@import 'app/panel-components/task-calendar/task-calendar.component.scss';
@import 'app/panel-components/widget-row/widget-row.component.scss';
@import 'app/panel-components/task-comments/task-comments.component.scss';
@import 'app/panel-components/follow-up-table/follow-up-table.component.scss';
@import 'app/panel-components/mention-table/mention-table.component.scss';
@import 'app/panel-components/claimed-tasks-table/claimed-tasks-table.component';
@import 'app/panel-components/button-row/button-row.component.scss';
@import 'app/panel-components/table-heading-row/table-heading-row.component.scss';
@import 'app/panel-components/upload-workflow-file/upload-workflow-file.component.scss';
@import 'app/panel-components/task-calendar/task-calendar.component.scss';
@import 'app/panel-components/task-list/task-list.component.scss';
@import 'app/panel-components/day-task-table/day-task-table.component.scss';
@import 'app/panel-components/user-table/user-table.component.scss';
@import 'app/panel-components/document-side-panel/document-side-panel.component.scss';

//Form Components

//Other
@import 'app/dialogs/add-comment-dialog/add-comment-dialog.component.scss';
@import 'app/dialogs/swap-upload-file-dialog/swap-upload-file-dialog.component.scss';
