.workflow-dropdown-item {
   padding: 0.25rem 0.5rem;
   border-radius: 3px;
   display: inline-flex;
   margin-right: 0.5rem;
   margin-top: 0.3rem;
   background-color: var(--primary-color);
   color: var(--primary-color-text);
   align-items: center;
}

.container {
   max-width: 1100px;
   margin: 0 auto;
}

.task-canvas {
   background-color: #f1ebff;
   padding: 0.7rem;
   border-radius: 0.5rem;
   width: 100%;
}

.heading-canvas {
   background-color: #967feb;
   border-radius: 0.3rem;
   width: fit-content;
   box-shadow: 2px 4px 5px rgba(24, 24, 24, 0.3);
}

.text-padding {
   margin-top: 12px;
}

.dropdown-width {
   width: 400px;
}

.field-margin {
   margin-top: 5px;
}

.text-color-dark {
   color: #403b91;
}

.tag-positioning {
   top: -13px;
   right: 34px;
}

.tag-padding {
   padding-top: 7px;
   padding-bottom: 7px;
   padding-left: 10px;
   padding-right: 10px;
}

.tag-font-size {
   font-size: 0.9rem;
}
