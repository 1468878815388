.pdf-container {
   width: 100%;
   height: 50em;
}

.panel-heading-grid {
   width: 100%;
}

.heading-due-date {
   margin: auto;
}

.panel-action-heading {
   margin-bottom: initial !important;
   margin-top: initial !important;
   padding-top: 0.5em;
}

.file-input {
   display: none;
}

.task-name-heading {
   padding-left: 3em;
}

.details-tab {
   span.p-accordion-toggle-icon {
      display: none;
   }
}

.container {
	max-width: 100%;
	margin: 0 auto 100px;
}

.card-task-detail {
   border-radius: 5px;
   background: #ffffff;
   padding: 12px 16px;
   box-sizing: border-box;
   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
   -moz-border-radius: 5px;
   -webkit-border-radius: 5px;
}

.button-position-left {
   top: 260px;
   left: -35px;
}

.button-position-right {
   top: 260px;
   right: -35px;
}
