.spinner {
   justify-content: center;
   align-items: center;
}

.container {
   max-width: 1100px;
   margin: 0 auto;
}

.widget-row-card {
	border-radius: 5px;
	background: white;
	box-sizing: border-box;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
	margin-bottom: 1rem;
}
