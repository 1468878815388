:host ::ng-deep .custom-spinner .p-progress-spinner-circle {
   animation:
      custom-progress-spinner-dash 1.5s ease-in-out infinite,
      custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {
   100%,
   0% {
      stroke: #16697a;
   }
   40% {
      stroke: #489fb5;
   }
   66% {
      stroke: #82c0cc;
   }
   80%,
   90% {
      stroke: #ffa62b;
   }
}

@keyframes custom-progress-spinner-dash {
   0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
   }
   50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
   }
   100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
   }
}

.day-cell-normal {
   text-align: center;
   opacity: 1 !important;

   .fc-event-main {
      color: black !important;
   }
}

.day-cell-overdue {
   text-align: center;
   opacity: 1 !important;

   .fc-event-main {
      color: rgb(201, 0, 0) !important;
   }
}

.day-cell-complete {
   text-align: center;
   opacity: 1 !important;

   .fc-event-main {
      color: green !important;
   }
}

.fc-daygrid-event {
   border: none !important;
   background-color: transparent !important;
}
