:host ::ng-deep .custom-spinner .p-progress-spinner-circle {
   animation:
      custom-progress-spinner-dash 1.5s ease-in-out infinite,
      custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {
   100%,
   0% {
      stroke: #16697a;
   }
   40% {
      stroke: #489fb5;
   }
   66% {
      stroke: #82c0cc;
   }
   80%,
   90% {
      stroke: #ffa62b;
   }
}

@keyframes custom-progress-spinner-dash {
   0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
   }
   50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
   }
   100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
   }
}

.small-col {
   width: 4.5rem;
   text-align: center;
}

.date-col {
   width: 8rem;
   text-align: center;
}

.daily-state-col {
   width: 6.5rem;
   text-align: center;
}

.p-paginator {
   .p-paginator-current {
      margin-left: auto;
   }
}

.p-progressbar {
   height: 0.5rem;
   background-color: #d8dadc;

   .p-progressbar-value {
      background-color: #607d8b;
   }
}

.table-header {
   display: flex;
   justify-content: space-between;
}

.p-calendar .p-datepicker {
   min-width: 25rem;

   td {
      font-weight: 400;
   }
}

.heading-text-span {
   display: inline-block;
   vertical-align: middle;
}
