#workflow_table > div.tyalls-table-name {
   padding: 0.5rem;
   text-align: left;
   font-size: 1.5rem;
   @extend .unclaimed-task-widget !optional;
   font-weight: normal;
   color: #ffffff;
   border-radius: 0.3em;
}

.header-spacing-top {
   margin-bottom: 0.5rem;
}

.header-spacing-bottom {
   margin-bottom: 1rem;
}
