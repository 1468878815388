.file-input {
   display: none;
}

div.p-timeline-event-separator {
   padding-top: 1em;
   padding-bottom: 1em;
}

div.p-timeline-event-content {
   padding-top: 2em !important;
}

.button-height {
   background-color: #f1ebff;
   height: 2rem;
   padding-left: 1rem;
   padding-right: 1rem;
   padding-top: 0.4rem;
   border-radius: 4px;
   font-size: 0.9rem;
   font-weight: 600;
}

.title-text {
   font-size: 0.9rem;
}

.title-text-supporting {
   font-size: 0.9rem;
   padding-top: 0.6rem;
}

.button-width {
   width: 9rem;
}

.schedule-tag-comments {
   padding-top: 0.3rem;
   padding-bottom: 0.4rem;
   padding-left: 0.6rem;
   padding-right: 0.6rem;
   width: fit-content;
   border-radius: 0.5rem;
   background-color: #967feb;
   border: solid 2px #967feb;
   font-size: 0.85rem;
   font-weight: 600;
   box-shadow: 2px 4px 5px rgba(24, 24, 24, 0.3);
   z-index: 2;
   top: -14px;
   right: 25px;
}

.details-card-orange {
   background: #fff5d6;
   color: #666151;
   border-radius: 5px;
   box-shadow: 2px 4px 5px rgba(112, 100, 56, 0.3);
}

.details-card-grey {
   background: #eeeeee;
   color: rgb(186, 186, 186);
   border-radius: 5px;
   box-shadow: 2px 4px 5px rgba(125, 122, 110, 0.3);
}

.details-bg {
   background-color: #fffdf1;
}

.details-icon {
   border-radius: 2rem;
   width: 1.5rem;
   height: 1.5rem;
   margin-right: 0.3rem;
}

.details-orange {
   background-color: #ffbf00;
}

.details-grey {
   background-color: #cbcbcb;
}

.details-border-grey {
   border: 1px solid rgb(241, 241, 241);
   width: 1px;
   height: 40%;
   margin-left: 0.6rem;
}

.details-border-orange {
   border: 1px solid #ffd968;
   width: 1px;
   height: 55%;
   margin-left: 0.6rem;
}

.button-position-left-files {
   top: 260px;
   left: -25px;
}

.button-position-right-files {
   top: 260px;
   right: -25px;
}
