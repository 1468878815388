.action-col {
   text-align: center;
}

#claimed_tasks_table > p-table div.p-datatable-header {
   padding: 1rem;
   text-align: left;
   font-size: 1.5rem;
   @extend .claimed-task-widget !optional;
   font-weight: normal;
   color: #ffffff;
   border-radius: 0.3em;
}
