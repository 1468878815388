.upload-div {
   text-align: center;
}

.text-padding {
   margin-top: 0.65rem;
}

.choose-button-padding {
   padding-top: 26px !important;
}

.title-text-supporting {
   font-size: 0.9rem;
}

.title-text {
	font-size: 0.9rem;
}
