.action-col {
   text-align: center;
}

#follow_up_table > div.tyalls-table-name {
   padding: 0.5rem;
   text-align: left;
   font-size: 1.5rem;
   @extend .follow-up-widget !optional;
   font-weight: normal;
   color: #ffffff;
   border-radius: 0.3em;
}
