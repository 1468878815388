.tyalls-table-heading {
   margin: 0;
   padding-top: 0.5em;
   padding-bottom: 0.5em;
   padding-left: 0.5em;
   font-size: 1.15rem;
}

.p-datatable-header {
   height: 4rem;
}

.p-datatable-tyalls {
   .p-paginator {
      padding: 1rem;
   }

   .p-datatable-thead > tr > th {
      text-align: left;
   }

   .p-dropdown-label:not(.p-placeholder) {
      text-transform: uppercase;
   }
}

.p-datatable-tyalls :not(.p-datatable-gridlines) {
   .p-datatable-tbody > tr > td {
      cursor: auto;
   }
}

/* Responsive */
.p-datatable-tyalls .p-datatable-tbody > tr > td .p-column-title {
   display: none;
}

@media screen and (max-width: 960px) {
   .p-datatable-tyalls {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
         display: none !important;
      }

      .p-datatable-tbody > tr {
         border-bottom: 1px solid var(--surface-d);

         > td {
            text-align: left;
            display: block;
            border: 0 none !important;
            width: 100% !important;
            float: left;
            clear: left;
            border: 0 none;

            .p-column-title {
               padding: 0.4rem;
               min-width: 30%;
               display: inline-block;
               margin: -0.4rem 1rem -0.4rem -0.4rem;
               font-weight: bold;
            }

            .p-progressbar {
               margin-top: 0.5rem;
            }
         }
      }
   }
}

.column-element-center {
   text-align: center !important;
   vertical-align: middle;
}
