.font-size {
   font-size: 0.9rem;
}

.resize-handle {
   resize: vertical !important;
}

.button-margin {
   margin-top: 10rem;
}
