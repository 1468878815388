.navigation-button {
   background-color: #f5f5f7;
}

.claimed-task-widget {
   background-color: gray;
}

.unclaimed-task-widget {
   background-color: #403b91;
}

.action-button {
   background-color: #5cb5ff;
}

.follow-up-widget {
   background-color: #f08a2a;
}

.mention-widget {
   background-color: #00846d;
}

.admin-headers {
   background-color: #403b91;
}

.green-tick {
   color: green;
}

.red-tick {
   color: red;
}

.gray-tick {
   color: gray;
}

.dark-purple-text {
   color: #2f2b72;
}
