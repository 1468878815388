.container {
   max-width: 1100px;
   margin: 0 auto;
}

.title-paddiing {
   padding-top: 0.6rem;
}

.divider {
   border-top: solid 1px #d4d4d4;
   margin-top: 14px;
   padding-bottom: 6px;
}

.task-canvas {
   background-color: #f1ebff;
   padding: 1rem;
   border-radius: 0.5rem;
   width: 100%;
}
