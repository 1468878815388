.progress-spinner {
   position: fixed;
   z-index: 999;
   height: 2em;
   width: 2em;
   overflow: visible;
   margin: auto;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
   content: '';
   display: block;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.53);
}

.daily-stats-table {
   padding-top: 1em;
   color: black;
   text-align: center;
}

.fc-toolbar-title {
   font-size: 15pt !important;
   color: #403b91;
}
