.chip-span {
   padding-top: 1em;
}

.comment-panel-header {
   display: initial;
}

.comment-panel {
   width: 100%;
   min-height: 10em;
   max-height: 50em;
   overflow-y: scroll;
   overflow-x: hidden;
}

.comment-name-header {
   padding-left: 1em;
   font-size: 0.8rem !important;
}

.comment-name-date {
   font-size: 0.8rem !important;
   padding-right: 1em;
}
