.widget-card {
   height: 3rem !important;
}

.widget-icon {
   font-size: 12px !important;
   width: 2.4rem !important;
   height: 2.4rem !important;
   font-style: inherit !important;
   font-weight: bold;
   box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
}

.widget-text {
   font-size: 1.15rem !important;
   color: white;
   opacity: 1;
   font-weight: bold;
}

.container {
   max-width: 1100px;
   margin: 0 auto;
}

.widget-row-card {
   border-radius: 5px;
   background: white;
   box-sizing: border-box;
   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
   margin-bottom: 1rem;
}
