.menu-heading {
   padding-bottom: 0.2em;
}

.menu-item-text {
   font-size: 11pt;
   color: white;
   letter-spacing: 0.02rem;
   opacity: 0.3;
}
