$daily: #5cb5ff;
$nonDaily: #ffae00;
$formStandard: lightgreen;
$other: white;
$private: #3c22a1;
$contracts: #aab9c8;

.task-card {
	background: #967feb;
	padding-top: 1.5rem;
	box-shadow: 2px 4px 5px rgba(24, 24, 24, 0.3);
}

.task-name {
	margin: inherit;
	font-size: 0.9em;
	padding-left: 0.5em;
	text-overflow: ellipsis;
	max-width: 30ch;
	overflow: hidden;
	white-space: nowrap;
}

.file-input {
	display: none;
}

.task-list-card {
	min-height: 25rem;
}

.assigned-to-text {
	color: #403b91;
}

.orange {
	color: #f08a2a;
}

.green {
	color: green;
}

.schedule-tag-daily {
	padding-top: 0.2rem;
	padding-bottom: 0.3rem;
	padding-left: 0.6rem;
	padding-right: 0.6rem;
	width: fit-content;
	border-radius: 0.5rem;
	background: $daily !important;
	border: solid 2px $daily !important;
	font-size: 0.85rem;
	font-weight: 600;
	color: white !important;
	box-shadow: 2px 4px 5px rgba(24, 24, 24, 0.3);
}
.schedule-tag-non-daily {
	padding-top: 0.2rem;
	padding-bottom: 0.3rem;
	padding-left: 0.6rem;
	padding-right: 0.6rem;
	width: fit-content;
	border-radius: 0.5rem;
	background-color: $nonDaily !important;
	border: solid 2px $nonDaily !important;
	font-size: 0.85rem;
	font-weight: 600;
	color: white !important;
	box-shadow: 2px 4px 5px rgba(24, 24, 24, 0.3);
}
.schedule-tag-private {
	padding-top: 0.2rem;
	padding-bottom: 0.3rem;
	padding-left: 0.6rem;
	padding-right: 0.6rem;
	width: fit-content;
	border-radius: 0.5rem;
	background-color: $private !important;
	border: solid 2px $private !important;
	font-size: 0.85rem;
	font-weight: 600;
	color: white !important;
	box-shadow: 2px 4px 5px rgba(24, 24, 24, 0.3);
}
.schedule-tag-contracts {
	padding-top: 0.2rem;
	padding-bottom: 0.3rem;
	padding-left: 0.6rem;
	padding-right: 0.6rem;
	width: fit-content;
	border-radius: 0.5rem;
	background-color: $contracts !important;
	border: solid 2px $contracts !important;
	font-size: 0.85rem;
	font-weight: 600;
	color: white !important;
	box-shadow: 2px 4px 5px rgba(24, 24, 24, 0.3);
}
.schedule-tag-form{
	padding-top: 0.2rem;
	padding-bottom: 0.3rem;
	padding-left: 0.6rem;
	padding-right: 0.6rem;
	width: fit-content;
	border-radius: 0.5rem;
	background-color: $formStandard !important;
	border: solid 2px $formStandard !important;
	font-size: 0.85rem;
	font-weight: 600;
	color: #072B07FF !important;
	box-shadow: 2px 4px 5px rgba(24, 24, 24, 0.3);
}

.file-tag {
	padding-top: 0.2rem;
	padding-bottom: 0.3rem;
	padding-left: 0.6rem;
	padding-right: 0.6rem;
	margin-right: 0.3rem;
	width: fit-content;
	border-radius: 0.5rem;
	background-color: white;
	border: solid 2px #f08a2a;
	font-size: 0.85rem;
	font-weight: 600;
	box-shadow: 2px 4px 5px rgba(24, 24, 24, 0.3);
}

.status-tag {
	padding-top: 0.2rem;
	cursor: pointer;
	padding-bottom: 0.3rem;
	padding-left: 0.6rem;
	padding-right: 0.6rem;
	margin-right: 0.3rem;
	width: fit-content;
	border-radius: 0.5rem;
	background-color: white;
	border: solid 2px green;
	font-size: 0.85rem;
	font-weight: 600;
	box-shadow: 2px 4px 5px rgba(24, 24, 24, 0.3);
}

.upload-button {
	background-color: #5751a9;
	padding-top: 0.35rem;
	padding-bottom: 0.5rem;
	padding-right: 1rem;
	padding-left: 1rem;
	color: white;
	border-radius: 0.4rem;
	border: solid 2px #5751a9;
	cursor: pointer;
}

.upload-button:hover {
	background-color: #403b91;
	border: solid 2px #403b91;
}

.edit-button {
	background-color: rgba(255, 255, 255, 0.5);
	padding-top: 0.35rem;
	padding-bottom: 0.5rem;
	padding-right: 1rem;
	padding-left: 1rem;
	color: #5751a9;
	border-radius: 0.4rem;
	border: solid 2px #5751a9;
	cursor: pointer;
	font-weight: 600;
}

.edit-button:hover {
	border: solid 2px #403b91;
	color: #403b91;
}
