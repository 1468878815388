$daily: #5cb5ff;
$nonDaily: #ffae00;
$formStandard: lightgreen;
$other: white;
$private: #3c22a1;
$contracts: #aab9c8;

.workflow-dot {
   height: 2em;
   width: 2em;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-left: 1.5em;
}
.workflow-dot-daily {
   background: $daily !important;
}

.workflow-dot-non-daily {
   background: $nonDaily !important;
}

.form-dot {
   background: $formStandard !important;
}

.workflow-badge-row {
   justify-content: left;
   align-items: center;
}

.workflow-daily-badge {
   .p-badge {
      background: $daily !important;
   }
}

.workflow-daily-tile {
   background-color: $daily !important;
}

//Non-Daily
.workflow-non-daily-badge {
   .p-badge {
      background: $nonDaily !important;
   }
}

.form-standard-badge {
   .p-badge {
      background: $formStandard !important;
   }
}

.workflow-non-daily-tile {
   background-color: $nonDaily !important;
}

.workflow-other-badge {
   .p-badge {
      background: $other !important;
   }
}

.workflow-other-tile {
   background-color: $other !important;
}

//Private
.workflow-private-badge {
   .p-badge {
      background: $private !important;
   }
}

.workflow-private-tile {
   background-color: $private !important;
}

//Contracts
.workflow-contracts-badge {
   .p-badge {
      background: $contracts !important;
   }
}

.workflow-contracts-tile {
   background-color: $private !important;
}

.workflow-badge {
   padding-right: 1em;
}
