.action-col {
   text-align: center;
}

#mention_table > div.tyalls-table-name {
   padding: 0.5rem;
   text-align: left;
   font-size: 1.5rem;
   @extend .admin-headers !optional;
   font-weight: normal;
   color: #ffffff;
   border-radius: 0.3em;
}
#mention_dashboard_table > div.tyalls-table-name {
   padding: 0.5rem;
   text-align: left;
   font-size: 1.5rem;
   @extend .mention-widget !optional;
   font-weight: normal;
   color: #ffffff;
   border-radius: 0.3em;
}
