.elements-center {
   display: flex;
   justify-content: center;
   align-items: center;
}

.elements-left {
   display: flex;
   justify-content: left;
   align-items: center;
}
